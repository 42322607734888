import React from "react";
import { useTranslate } from "react-admin";
import makeStyles from "@material-ui/core/styles/makeStyles";
import ChapitreHelper from "../../helpers/ChapitreHelper";
import { Create, Edit } from "react-admin";
import useCurrentUser from "../../hooks/useCurrentUser";
import ChapitreReservationShow from "./ChapitreReservationShow";
import ChapitreReservationForm from "./ChapitreReservationForm";

export const chapitreReservationMobileViewBreakpoint = "sm";

const useStyles = makeStyles((theme) => ({
    root: {
        background: theme.palette.background.default,
        margin: theme.spacing(0),
        [theme.breakpoints.down(chapitreReservationMobileViewBreakpoint)]: {
            margin: theme.spacing(0, -4),
        },
    },
    content: {
        maxWidth: 1024,
        margin: "auto",
        padding: theme.spacing(3, 3),
    },
    estimate: {
        background: theme.palette.background.paper,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: theme.spacing(1),
        boxSizing: "border-box",
        margin: theme.spacing(1, 0),
        ...theme.typography.button,
        textTransform: "none",
    },
    actions: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-end",
    },
    participantsTitle: {
        marginTop: theme.spacing(1),
    },
    dialogActions: {
        padding: theme.spacing(2, 2),
        textAlign: "center",
        justifyContent: "center",
    },
    participantWaitingList: {
        opacity: 0.8,
    },
    participant: {},
    cardActions: {
        paddingTop: 0,
        justifyContent: "flex-end",
        verticalAlign: "middle",
        display: "flex",
        flexDirection: "row",
    },
}));

/**
 *
 * @param {object} props
 * @param {ChapitreHelper} props.chapitre
 */
const ChapitreReservation = ({
    chapitre,
    id,
    action,
    redirect,
    debug = false,
    ...props
}) => {
    const translate = useTranslate();
    const classes = useStyles();
    const user = useCurrentUser();
    const view = action ? action : id ? "show" : "create";
    let Component;
    const componentProps = {};
    switch (view) {
        case "create":
            Component = Create;
            componentProps.record = {
                chapitre: chapitre.id,
            };
            break;
        case "edit":
            Component = Edit;
            componentProps.undoable = false;
            componentProps.id = id;
            break;

        default:
            componentProps.id = id;
            break;
    }
    return (
        <div className={classes.root} {...props}>
            <div className={classes.content}>
                {Component ? (
                    <>
                        <h2>{translate("Votre réservation")}</h2>
                        <Component
                            resource={"reservationweb"}
                            basePath="/reservationweb"
                            component="div"
                            {...componentProps}
                        >
                            <ChapitreReservationForm
                                redirect={redirect}
                                classes={classes}
                                chapitre={chapitre}
                                user={user}
                                isEdit={view === "edit"}
                            />
                        </Component>
                    </>
                ) : (
                    <>
                        <ChapitreReservationShow
                            chapitre={chapitre}
                            {...componentProps}
                            classes={classes}
                        />
                    </>
                )}
            </div>
        </div>
    );
};

export default ChapitreReservation;
